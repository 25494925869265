<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit Site </h4>
                        <form class="needs-validation" @submit.prevent="updateSite">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Site Category</label>
                                        <multiselect  :preselect-first="true" v-model="siteData.site_category" @select="selected" :options="options2" :multiple="false"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group position-relative">
                                        <label>PTID</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" v-if="displayForm===1">CMR-EIDPOC-</span>
                                                <span class="input-group-text" v-if="displayForm===2">CMR-VL-</span>
                                                <span class="input-group-text" v-if="displayForm===0">{{this.siteData.ptid.split(" ")[0]}}</span>
                                            </div>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="PTID"
                                                    v-model="this.siteData.ptid.split(' ')[1]"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">

                                        <label for="validationCustom01">Site Name *</label>
                                        <input
                                                id="validationCustom01"
                                                v-model="siteData.site_name"
                                                type="text"
                                                class="form-control"
                                                placeholder="Site name"
                                                :class="{ 'is-invalid': submitted && $v.form.fname.$error }"
                                        />
                                        <div v-if="submitted && $v.site_name.$error" class="invalid-feedback">
                                            <span v-if="!$v.site_name.required">This value is required.</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Region *</label>
                                        <multiselect v-model="region"
                                        label="name"
                                                     :options="options"
                                                     :multiple="false"
                                                     placeholder="Select region">

                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Location *</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="location"
                                                v-model="siteData.location"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Platform Type *</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Platform Type"
                                                v-model="siteData.platform_type"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >District *</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="District"
                                                v-model="siteData.district"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Enrollment status</label>

                                        <date-picker
                                                v-model="siteData.enrollment_status"
                                                type="year"
                                                lang="en"
                                                confirm
                                                placeholder="Enrolled since"
                                        ></date-picker>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Type of facility </label>
                                        <multiselect v-model="siteData.type_facility" :options="options1" :multiple="false"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Contact Person</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Contact Person"
                                                v-model="siteData.contact_person"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Telephone</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Telephone"
                                                v-model="siteData.telephone"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                v-model="siteData.email"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Facility e-mail</label>
                                        <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Facility Email"
                                                v-model="siteData.facility_email"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Head of testing site</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Head of testing site"
                                                v-model="siteData.head_of_testingsite"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Phone of head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Phone of head"
                                                v-model="siteData.phone_of_head"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Email of head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Email of head"
                                                v-model="siteData.email_of_head"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Head of facility</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Head of facility"
                                                v-model="siteData.head_of_facility"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Phone of facility head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Phone of head"
                                                v-model="siteData.phone_of_facility_head"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Email of facility head</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Email of facility head"
                                                v-model="siteData.email_of_facility_head"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <textarea
                                                v-model="siteData.comments"
                                                class="form-control"
                                                :maxlength="225"
                                                rows="3"
                                                placeholder="Comment"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Save</button>
                        </form>
                    </div>
                </div>
                <div class="lodader__content" v-if="loader">
                    <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                </div>
                <!-- end card -->
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";

    import {
        required,
        email,
        minLength,
        sameAs,
        maxLength,
        minValue,
        maxValue,
        numeric,
        url,
        alphaNum
    } from "vuelidate/lib/validators";
    import {axios_get, axios_put} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Edit EID",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, Multiselect,DatePicker,PageHeader },
        data() {
            return {
                title: "Edit Site",
                siteData:null,
                loader: false,
                ptidData:null,
                region:null,
                displayForm:0,
                items: [
                    {
                        text: "EID Sites",
                        href: "/sites/eid"
                    },
                    {
                        text: "Edit Site",
                        active: true
                    }
                ],
                form: {
                    fname: "",
                    lname: "",
                    city: "",
                    state: "",
                    zipcode: ""
                },
                tooltipform: {
                    fname: "",
                    lname: "",
                    zipcode: "",
                    city: "",
                    state: ""
                },
                range: {
                    minlen: "",
                    maxlength: "",
                    between: "",
                    minval: "",
                    maxval: "",
                    rangeval: "",
                    expr: ""
                },
                typeform: {
                    name: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    url: "",
                    digit: "",
                    number: "",
                    alphanum: "",
                    textarea: ""
                },
                options: [],
                options1: [
                    "Confessional ",
                    "Faith based",
                    "Parastatal",
                    "Public",
                    "NGO",
                ],
                options2: [
                    "EID",
                    "VL",
                ],
                prefix:"",
                submitted: false,
                submitform: false,
                submit: false,
                typesubmit: false,
                value1: null,
                value2: null,
            };

        },
        validations: {
            form: {
                fname: { required },
                lname: { required },
                city: { required },
                state: { required },
                zipcode: { required }
            },
            tooltipform: {
                fname: { required },
                lname: { required },
                username: { required },
                city: { required },
                state: { required }
            },
            range: {
                minlen: { required, minLength: minLength(6) },
                maxlength: { required, maxLength: maxLength(6) },
                between: { required, minLength: minLength(5), maxLength: maxLength(10) },
                minval: { required, minValue: minValue(6) },
                maxval: { required, maxValue: maxValue(6) },
                rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
                expr: { required }
            },
            typeform: {
                name: { required },
                password: { required, minLength: minLength(6) },
                confirmPassword: { required, sameAsPassword: sameAs("password") },
                email: { required, email },
                url: { required, url },
                digit: { required, numeric },
                number: { required, numeric },
                alphanum: { required, alphaNum },
                textarea: { required }
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            formSubmit(e) {
                this.submitted = true;
                // stop here if form is invalid
                this.$v.$touch();
            },

            tooltipForm() {
                this.submitform = true;
                this.$v.$touch();
            },

            /**
             * Range validation form submit
             */
            // eslint-disable-next-line no-unused-vars
            rangeform(e) {
                this.submit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            /**
             * Validation type submit
             */
            // eslint-disable-next-line no-unused-vars
            typeForm(e) {
                this.typesubmit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            selected(value2){
                switch (value2) {
                    case 'EID':
                        this.displayForm=1;
                        this.prefix="CMR-EIDPOC- ";

                        break;
                    case 'VL':
                        this.displayForm=2;
                        this.prefix="CMR-VL- ";
                        break
                    default:
                        this.displayForm=0;
                        this.prefix=this.siteData.ptid.split(" ")[0]

                }
                console.log(value2)

            },
            updateSite(){
                this.loader = true
                console.log(this.region)
                this.siteData.region = this.region.id
                console.log(this.siteData)
                axios_put("/sites/"+this.id,this.siteData,(d)=>{
                    console.log('success', d);
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    this.loader = false
                    console.log("finishing");
                })

            },
        },
        mounted() {
            
            this.id=this.$route.params.id
                axios_get("/sites/"+this.id,{},(d)=>{
                        console.log(d);
                        this.siteData=d[0];
                        this.region=d[0].region;
                        console.log(this.region)
                        this.siteData.enrollment_status = new Date(this.siteData.enrollment_status)
                        this.ptidData=this.siteData.ptid.split(" ");
                        console.log(new Date(this.siteData.enrollment_status).toISOString())
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );
                var country_id = JSON.parse(window.localStorage.getItem('user')).country_id
                axios_get("/regions?country_id="+country_id,{},(d)=>{
                        console.log(d);
                        this.options=d;
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );

        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>